<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                <span class="text-before-button">
                  {{ resellerService.name }}
                </span>
                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_RESELLER_SERVICES)
                  "
                  type="primary"
                  icon
                  size="sm"
                  @click="editResellerService()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_DELETE_RESELLER_SERVICES)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteResellerService()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'reseller-services'"
                :objectId="resellerService.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <reseller-service-view-global
                    :resellerService="resellerService"
                  />
                </tab-pane>

                <tab-pane title="gallery" id="2">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GALLERY") }}
                  </span>
                  <reseller-service-view-gallery
                    :resellerService="resellerService"
                  />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <resellerService-view-logs
                    :resellerService="resellerService"
                  />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import defaultResellerService from "./defaultResellerService";
import ResellerServiceViewGlobal from "./partials/ResellerServiceViewGlobal.vue";
import ResellerServiceViewLogs from "./partials/ResellerServiceViewLogs.vue";
import ResellerServiceViewGallery from "./partials/ResellerServiceViewGallery.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ResellerServiceViewGlobal,
    ResellerServiceViewGallery,
    ResellerServiceViewLogs,
    NotificationSubscription,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      resellerService: cloneDeep(defaultResellerService),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("resellerServices/get", id);
        this.resellerService =
          this.$store.getters["resellerServices/resellerService"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editResellerService() {
      this.$router.push({
        name: "Edit ResellerService",
        params: { id: this.resellerService.id },
      });
    },

    async deleteResellerService() {
      const confirmation = await swal.fire({
        title: this.$t("RESELLER_SERVICES.DELETE_THIS_RESELLER_SERVICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "resellerServices/destroy",
            this.resellerService.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("RESELLER_SERVICES.RESELLER_SERVICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List ResellerServices" });
    },
  },
};
</script>
